<template>
    <div class="float fix">
        <div class="content flex wrap center vcenter">
            <div class="waiting flex wrap center vcenter">
                <img v-lazy="'/images/wicon.webp'" alt="آتیاکراد" loading="lazy">
                <p>درحال بارگذاری داده‌ها {{ dots }}</p>
            </div>
        </div>
        <div class="overlay blur"></div>
    </div>
</template>

<script>
/* eslint-disable */
export default {
    name: 'Loading',
    created() {
        setInterval(() => {
            if (this.dots === '')
                this.dots = '.'
            else if (this.dots === '.')
                this.dots = '..'
            else if (this.dots === '..')
                this.dots = '...'
            else
                this.dots = ''
        }, 500)
    },
    data() {
        return {
            dots: ''
        }
    }
}
</script>

<style src="../assets/css/components/loading.css"></style>