/* eslint-disable */
import cookies from 'vue-cookies'
import util from './util'

import {
  getMessaging,
  deleteToken
} from "firebase/messaging"

const crypto = require('crypto-js')

function generate() {
  let token = cookies.get('identifier')
  return crypto.AES.encrypt(JSON.stringify({
    issue: Date.now(),
    expire: Date.now() + 31246,
    token: token ? token : '',
    salt: Math.random()
  }), crypto.enc.Utf8.parse(atob('SlhxWmFDeXhQcTFVc2h6eGJDNy95b2ZEbXlOZVVRST0=')), {
    iv: crypto.enc.Utf8.parse(atob('RVI5UFpOc2wvckVsKzd1aw==')),
    mode: crypto.mode.CBC
  }).toString()
}

function session() {
  const ua = window.navigator.userAgent
  return {
    data: {
      uid: cookies.get('uid') ? cookies.get('uid') : '00000000-0000-0000-0000-000000000000',
      rid: cookies.get('rid') ? cookies.get('rid') : '00000000-0000-0000-0000-000000000000',
      token: cookies.get('identifier') ? cookies.get('identifier') : '',
      device: 'WebKit',
      os: ua.includes('Windows') ?
        'Windows' : ua.includes('Linux') ?
        'Linux' : ua.includes('mac') ?
        'macOS' : 'Other',
      agent: `Web ${ua}`,
      ip: localStorage.getItem('ipaddr')
    }
  }
}

function identify(routes) {
  if (!cookies.get('identifier') && util.validate(routes.filter(path => {
      return window.location.pathname.toLowerCase().includes(path)
    })))
    logout(routes)
}

function logout(routes = []) {
  if (window.location.pathname !== '/Authentication') {
    localStorage.clear()
  }
  ['identifier', 'uid', 'gid', 'rid', 'grade', 'username', 'mobile', 'display_name', 'referr', 'balance', 'inprocess', 'fcm']
  .forEach((cookie) => cookies.remove(cookie))

  const messaging = getMessaging()
  deleteToken(messaging).then(() => {}).catch((err) => {})

  if (util.validate(routes.filter(path => {
      return window.location.pathname.toLowerCase().includes(path)
    })))
    window.location.pathname = '/Authentication'
}

export default {
  generate,
  session,
  identify,
  logout
}
