<template>
  <Loading v-if="loading"></Loading>
  <RouterView :authenticated="authenticated" :grade="grade" :time="time" :credits="credits" :memorandums="memorandums"
    :notices="notices" :status="status" :path="path" :excluded="excluded" @onloading="(status) => {
      this.loading = status
    }" />
  <notifications position="top left" :duration="6000" :pauseOnHover="true" />
  <vue3-confirm-dialog />
</template>

<script>
/* eslint-disable */
import { computed } from 'vue'

import RouterView from 'vue-router'
import Loading from './components/Loading.vue'

import { getMessaging, getToken, onMessage } from 'firebase/messaging'

export default {
  name: 'App',
  inject: ['base'],
  components: {
    Loading,
    RouterView
  },
  provide() {
    return {
      entities: computed(() => this.entities)
    }
  },
  mounted() {
    if (this.inspect) {
      document.addEventListener('contextmenu', (e) => e.preventDefault())
      document.onkeydown = (e) => {
        if (e.key === 123)
          e.preventDefault()
        if (e.ctrlKey && e.shiftKey && e.key === 'I')
          e.preventDefault()
        if (e.ctrlKey && e.shiftKey && e.key === 'C')
          e.preventDefault()
        if (e.ctrlKey && e.shiftKey && e.key === 'J')
          e.preventDefault()
        if (e.ctrlKey && e.key === 'U')
          e.preventDefault()
      }
    }

    const worker = new Worker('/app-worker.js')
    worker.onmessage = (event) => {
      const { action, data } = event.data
      switch (action) {
        case 'loadStyles':
          const link = document.createElement('link')
          link.rel = 'stylesheet'
          link.href = data.href
          document.head.appendChild(link)

          if (data.cache)
            localStorage.setItem('fonts', Date.now())
          break

        case 'loadScript':
          window.RAYCHAT_TOKEN = "6b6772f6-78fb-49ae-990c-fe4b7a8ce4b9"
          const script = document.createElement('script')
          script.src = data.src
          script.async = 1
          document.head.appendChild(script)
          break

        case 'showNotification':
          document.addEventListener('click', () => {
            Notification.requestPermission().then((permission) => {
              if (permission === 'granted') {
                const messaging = getMessaging()
                onMessage(messaging, (payload) => {
                  this.base.Notification().toast(payload.notification.title
                    , payload.notification.body)
                })
                getToken(messaging, {
                  vapidKey: 'BDVPk8P67viQIfuucm2BfU8Qxigfnw9Sz4yg9vUMjrW1NV4PCbB6Mn5mQWnHwVaSxEb7taX-tB-hVodHOoXYGU4'
                }).then((currentToken) => {
                  if (currentToken) {
                    this.$cookies.set('fcm', currentToken)
                    if (!this.authenticated && localStorage.getItem('fcm') !== '1')
                      this.base.API().post('Notice/Token/Register', {
                        data: [{
                          uid: '00000000-0000-0000-0000-000000000000',
                          sid: '00000000-0000-0000-0000-000000000000',
                          token: currentToken
                        }]
                      }, (response) => {
                        localStorage.setItem('fcm', '1')
                      })
                    this.fcm(currentToken)
                  }
                }).catch((error) => { })
              }
            })
          })
          break
        case 'getItem':
          worker.postMessage({ action: 'returnItem', key: data.key, value: localStorage.getItem(data.key) })
          break
      }
    }
  },
  created() {
    this.init()
  },
  data() {
    return {
      uid: '',
      gid: '',
      rid: '',
      grade: 0,
      identifier: '',
      authenticated: false,
      socket: null,
      time: 0,
      entities: eval(localStorage.getItem('entities') || [0])[0],
      credits: 0,
      memorandums: [],
      notices: [],
      status: 0,
      inspect: false,
      path: window.location.pathname.toLowerCase(),
      routes: ['/dashboard'],
      loading: false
    }
  },
  watch: {
    $route(from, to) {
      this.path = window.location.pathname.toLowerCase()
      if (!this.base.Util().validate(this.entities))
        this.entities = eval(localStorage.getItem('entities') || [0])[0]
    },
    authenticated(newAuthenticated, oldAuthenticated) {
      if (this.authenticated)
        this.socket.port.postMessage({
          type: 'init',
          cookies: {
            uid: this.uid,
            gid: this.gid,
            rid: this.rid,
            identifier: this.identifier
          },
        })
      else
        this.socket.port.postMessage({
          type: 'disconnect',
        })
    }
  },
  methods: {
    init() {
      this.authenticate(true)
      setInterval(() => this.authenticate(), 1000)

      if (window.location.pathname !== '/') {
        this.routing()
        this.base.Security().identify(this.routes)
      }
    },
    shared() {
      if (!this.socket) {
        this.socket = new SharedWorker('/websocket-worker.js')
        this.socket.port.onmessage = (event) => {
          const { type, data } = event.data

          switch (type) {
            case 'connected':
              setTimeout(() => {
                this.time = Number(localStorage.getItem('time')) || 0
                this.credits = String(Number(this.$cookies.get('balance') || 0) - Number(this.$cookies.get('inprocess') || 0))
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')

                this.status = localStorage.getItem('status') || '-1'
              }, 1000)
              break

            case 'message':
              let response = JSON.parse(data)
              if (!response?.isTrusted) {
                if (response.span) {
                  this.time = Number(response.span)
                  localStorage.setItem('time', this.time)
                }

                if (response.credits) {
                  this.credits = String(Number(response.credits.balance || 0) - Number(response.credits.inprocess || 0)).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  this.$cookies.set('balance', String(response.credits.balance || 0).replace(/\s/g, '').replace(/,/g, ''))
                  this.$cookies.set('inprocess', String(response.credits.inprocess || 0).replace(/\s/g, '').replace(/,/g, ''))
                }

                if (response.metas)
                  localStorage.setItem('metas', JSON.stringify([response.metas]))

                if (response.entities)
                  localStorage.setItem('entities', JSON.stringify([response.entities]))

                if (response.memorandums)
                  this.memorandums = response.memorandums

                if (response.message === 'logout') {
                  try {
                    this.socket?.close()
                  } catch (e) {
                  }
                  this.base.Security().logout()
                }

                if (response.accesses)
                  localStorage.setItem('accesses', JSON.stringify(response.accesses))

                if (response.categories && response.menus) {
                  this.modules = {
                    categories: response.categories.sort((m1, m2) => (m1.order > m2.order) ? 1 : (m1.order < m2.order) ? -1 : 0),
                    menus: response.menus.sort((m1, m2) => (m1.order > m2.order) ? 1 : (m1.order < m2.order) ? -1 : 0),
                    options: response.options?.sort((m1, m2) => (m1.order > m2.order) ? 1 : (m1.order < m2.order) ? -1 : 0),
                  }
                  localStorage.setItem('modules', JSON.stringify([this.modules]))
                }

                if (response.status) {
                  this.status = response.status === 'under_maintenance'
                    ? '2'
                    : '1'
                  localStorage.setItem('status', this.status)
                }
              }
              break

            case 'retryConnection':
              this.status = '0'
              localStorage.setItem('status', this.status)
              break
          }
        }
        this.socket.port.start()
      }
      this.socket.port.postMessage({
        type: 'init',
        cookies: {
          uid: this.uid,
          gid: this.gid,
          rid: this.rid,
          identifier: this.identifier
        },
      })
    },
    routing() {
      if (!this.authenticated && this.base.Util().validate(this.routes.filter(path => {
        return window.location.pathname.toLowerCase().includes(path)
      })))
        window.location.pathname = '/Authentication'
      else if (this.authenticated && (this.path === '/authentication' || this.path === '/register'))
        window.location.pathname = '/Dashboard'
    },
    authenticate(init) {
      this.uid = this.$cookies.get('uid')
      this.gid = this.$cookies.get('gid')
      this.rid = this.$cookies.get('rid')
      this.grade = Number(this.$cookies.get('grade'))
      this.identifier = this.$cookies.get('identifier')
      this.authenticated = this.base.Util().validate(this.uid)
        && this.base.Util().validate(this.gid) && this.base.Util().validate(this.rid)
        && this.base.Util().validate(this.grade) && this.base.Util().validate(this.identifier)

      this.base.Security().identify(this.routes)

      if (init)
        this.shared()
    },
    fcm(token) {
      if (this.base.Util().validate(token)
        && this.socket && this.socket.readyState === this.socket.OPEN) {
        if (this.path.includes('/dashboard'))
          this.socket.send(`FCMToken->${token}`)
      }
      else
        setTimeout(() => this.fcm(token), 1500)
    }
  }
}
</script>

<style src="../src/assets/css/main.css" />

<style lang="less" src="../src/assets/css/main.less" />

<style src="vue-step-progress/dist/main.css" />

<style src="../node_modules/vue3-confirm-dialog/dist/style.css" />